import React from 'react';
import photo from '../../../assets/photo.svg';
import cancelIcon from '../../../assets/cancel.svg';

const ProductImageUpload = ({ imageSrc, imageName, onFileChange, onRemove, error }) => {
  return (
    <div className="col-span-full">
      <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
        Product Image
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-4 relative">
        {imageSrc === null ? (
          <div className="text-center">
            <img src={photo} alt="photo" aria-hidden="true" className="mx-auto h-10 w-10" />
            <div className="mt-2 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor="image-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600"
              >
                <span className="ms-10">Upload a image</span>
                <input id="image-upload" name="image" type="file" className="sr-only" onChange={onFileChange} />
              </label>
            </div>
            <p className="text-xs leading-5 text-gray-600">PNG, JPG, JPEG, WEBP up to 1MB</p>
          </div>
        ) : (
          <div>
            <div className="px-3 right-0 absolute">
              <img
                src={cancelIcon}
                alt="cancel-icon"
                className="w-5 cursor-pointer"
                title="Remove"
                onClick={onRemove}
              />
            </div>
            <div className="text-center">
              <img src={imageSrc} alt="photo" aria-hidden="true" className="mx-auto max-h-24 max-w-24 object-contain" />
              <p className="text-xs leading-5 text-gray-600">File name: {imageName}</p>
            </div>
          </div>
        )}
      </div>
      {error && <p className="text-red">{error}</p>}
    </div>
  );
};

export default ProductImageUpload;
