import React, { Suspense, lazy  }  from "react";
import { Link } from 'react-router-dom'
const ProductsList = lazy(() => import('./ProductsList'));

function Products(){
    return(
        <>
            <div className="p-4 max-w-full mx-auto mt-12 font-noto">
                <div className="flex justify-between mb-4">
                    <p className='text-2xl font-bold text-black'>Products</p>
                    <div>
                        <Link to='/product/add-product' className=" text-white font-noto rounded-xl px-4 py-2 bg-red" >Add Product</Link>
                    </div>
                </div>
                <Suspense
                    fallback={<div>Loading please wait...</div>}
                >
                    <ProductsList/>
                </Suspense>
            </div>
        </>
    )
}

export default Products