import React, { useState,useEffect }  from "react";
import axios from 'axios';
import config from "../../../config";
import leftArrow from '../../../assets/left-arrow.svg'
import { Link} from "react-router-dom";
import ProductImageUpload from "../components/ProductImageUpload";
import ProductPDFUpload from '../components/ProductPDFUpload';
import CKEditorWrapper from "../components/CKEditorWrapper";
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddProduct(){
    const notify = () => toast.success("Product added successfully!", {
        position: "top-right"
    });

    const [options,setOptions]=useState({
        categoryOptions:{},
        subCategoryOptions:{}
    })

    const [formdata,setFormData]=useState({
        parentCategory:null,
        subCategory:null,
        title:'',
        description:'',
        specifications:'',
        advantages:'',
        applications:'',
        image:null,
        pdf:null,
        imageSrc:null,
        pdfSrc:null,
        imageName:null
    });

    useEffect(()=>{
        axios.get(`${config.baseURL}/get-sub-categories`,{ 
            withCredentials: true 
          })
        .then((res)=>{
            if(res.data.status==='success'){
                const options = res.data.data.map(item => ({
                    value: item.slug, 
                    label: item.name
                }));
                setOptions({
                    ...options,
                    categoryOptions:options,
                });
            }
        })
        .catch((err)=>console.log(err))
    },[])
   
    const [errors, setErrors] = useState({}); 

    const validate = () => {
        let errors = {};

        if (!formdata.parentCategory) {
            errors.parentCategory = 'Category is required';
        }

        if (!formdata.subCategory) {
            errors.subCategory = 'Sub Category is required';
        }

        if (!formdata.title || formdata.title.trim()==="") {
          errors.title = 'Title is required';
        }

        if (!formdata.description || formdata.description.trim()==="") {
          errors.description = 'Description is required';
        }

        if (!formdata.specifications || formdata.specifications.trim()==="") {
            errors.specifications = 'Specifications is required';
        }

        if (!formdata.advantages || formdata.advantages.trim()==="") {
            errors.advantages = 'Advantages is required';
        }

        if (!formdata.applications || formdata.applications.trim()==="") {
            errors.applications = 'Applications is required';
        }

        if(formdata.imageSrc===null){
            if (!formdata.image) {
                errors.image = 'Image file is required';
            } else if (!['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(formdata.image.type)) {
                errors.image = 'Only PNG and JPEG, JPG, WEBP images are allowed';
            }
        }
        if(formdata.pdfSrc===null){
            if (!formdata.pdf) {
                errors.pdf = 'PDF file is required';
            } else if (formdata.pdf.type !== 'application/pdf') {
                errors.pdf = 'Only PDF files are allowed';
            }
        }
    
        return errors;
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formdata, 
          [name]: value 
        });
    };

    const fileHandle=(e)=>{
        const { name } = e.target;
        const file = e.target.files[0];

        if(name==='image'){
            const validTypes = ['image/jpeg', 'image/png','image/jpg','image/webp'];
            if (validTypes.includes(file.type)) {
                setFormData({
                    ...formdata, 
                    [name]: file 
                });
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFormData((prevData) => ({
                        ...prevData,
                        imageSrc: reader.result, 
                        imageName:file.name
                    }));
                };
                reader.readAsDataURL(file); 
            }else{
                e.target.value = ''; 
            }
        }else{
            if(file.type === 'application/pdf'){
                setFormData({
                    ...formdata, 
                    [name]: file 
                });
                const pdfUrl = URL.createObjectURL(file); 
                setFormData((prevData) => ({
                    ...prevData,
                    pdfSrc: pdfUrl, 
                }));
            }else{
                e.target.value = ''; 
            }
        }
    }

    const formHandler=(e)=>{
        e.preventDefault();
        // console.log('Form submitted', formdata);
        const validationErrors = validate(); 
        if (Object.keys(validationErrors).length === 0) {
            
          const formData = new FormData();
          formData.append('category', JSON.stringify(formdata.parentCategory));
          formData.append('subCategory', JSON.stringify(formdata.subCategory));
          formData.append('description', formdata.description);
          formData.append('title', formdata.title);
          formData.append('image', formdata.image);
          formData.append('pdf',formdata.pdf );
          formData.append('specifications',formdata.specifications );
          formData.append('advantages',formdata.advantages );
          formData.append('applications',formdata.applications);

          axios.put(`${config.baseURL}/add-product`,formData,{ 
            withCredentials: true 
          })
          .then((res)=>{
            if(res.data.status==="success"){
                dataHandler();
                notify(); 
            }
          })
          .catch((err)=>console.log(err))

        } else {
          setErrors(validationErrors);
        }
    }

    const dataHandler=()=>{
        setFormData({
            parentCategory:null,
            subCategory:null,
            title:'',
            description:'',
            specifications:'',
            advantages:'',
            applications:'',
            image:null,
            pdf:null,
            imageSrc:null,
            pdfSrc:null,
            imageName:null
        });
    }

    const categoryHandle = (selectedOption) => {
        axios.put(`${config.baseURL}/get-parent-sub-categories`,{data:selectedOption},{ 
            withCredentials: true 
          })
        .then((res)=>{
            if(res.data.status==='success'){
                const getoptions = res.data.data.map(item => ({
                    value: item.slug, 
                    label: item.name
                }));
                // console.log(options);
                setOptions({
                    ...options,
                    subCategoryOptions:getoptions
                });
            }
        })
        .catch((err)=>console.log(err))

        setFormData({
            ...formdata,
            parentCategory:selectedOption
        })
    };

    return (
    <>
        <div className="w-full lg:w-6/12 font-noto">
            <ToastContainer />
            <div className="pb-12">
                <div className="my-3"><Link to="/product/products"><img src={leftArrow} alt="Left-Icon" className="cursor-pointer" title="Back"/></Link></div>
                <h2 className="ms-3 text-xl  mt-5 font-semibold leading-7 text-gray-900 ">Add Product</h2>
                <form onSubmit={formHandler} className="ms-3 mt-3 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-4">
                    <div className="col-span-full">
                        <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">
                            Product Category
                        </label>
                        <div className="mt-2">
                            <Select
                               options={options.categoryOptions}
                               onChange={categoryHandle}
                               id='category'
                            />
                        </div>
                        {errors.parentCategory && <p className="text-red">{errors.parentCategory}</p>}
                    </div>   
                    <div className="col-span-full">
                        <label htmlFor="subCategory" className="block text-sm font-medium leading-6 text-gray-900">
                            Sub Category
                        </label>
                        <div className="mt-2">
                            <Select
                               options={options.subCategoryOptions}
                               id='subCategory'
                               onChange={(selectedOption)=>setFormData((prevData)=>({...prevData,subCategory:selectedOption}))}
                            />
                        </div>
                        {errors.subCategory && <p className="text-red">{errors.subCategory}</p>}
                    </div>                              
                    <div className="col-span-full">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Product Title
                        </label>
                        <div className="mt-2">
                            <input
                                id="title"
                                name="title"
                                type="text"
                               
                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-insetsm:text-sm sm:leading-6 focus:ring-red focus:outline-none"
                                value={formdata.title}
                                onChange={handleChange}
                                
                            />
                        </div>
                        {errors.title && <p className="text-red">{errors.title}</p>}
                    </div>
                    <div className="col-span-full">
                        <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                            Description
                        </label>
                        <div className="mt-2">
                            <textarea
                                id="description"
                                name="description"
                                rows={5}
                                className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red sm:text-sm sm:leading-6 focus:outline-none"
                                value={formdata.description}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.description && <p className="text-red">{errors.description}</p>}
                    </div>

                    <CKEditorWrapper 
                        id="specifications"
                            label="Specifications"
                            data={formdata.specifications}
                            onChange={(data) => setFormData((prevData)=>({...prevData,specifications:data}))}
                        error={errors.specifications}
                    />
                    <CKEditorWrapper
                        id="advantages"
                        label="Advantages"
                        data={formdata.advantages}
                        onChange={(data) => setFormData((prevData)=>({ ...prevData,advantages:data}))}
                        error={errors.advantages}
                    />

                    <CKEditorWrapper
                        id="applications"
                        label="Applications"
                        data={formdata.applications}
                        onChange={(data) =>  setFormData((prevData)=>({ ...prevData, applications:data}))}
                        error={errors.applications}
                    />

                    <ProductImageUpload
                        imageSrc={formdata.imageSrc}
                        imageName={formdata.imageName}
                        onFileChange={fileHandle}
                        onRemove={() => setFormData((prevData)=>({ ...prevData, imageSrc: null }))}
                        error={errors.image}
                    />  

                    <ProductPDFUpload 
                        pdfSrc={formdata.pdfSrc}
                        onFileChange={fileHandle}
                        onRemove={() => setFormData((prevData)=>({ ...prevData, pdfSrc: null }))}
                        error={errors.pdf}
                    />

                    <div className="mt-6 flex items-center justify-end gap-x-6 col-span-full">
                        <Link to="/product/products" className="text-sm  leading-6 text-gray-900">Cancel</Link>
                        <button type="submit" className="rounded-md bg-red px-3 py-2 text-sm  text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Add</button>   
                    </div>     
                </form>
            </div>
        </div>
    </>
    )
}

export default AddProduct